import React from "react";
import { graphql, Link, PageProps } from "gatsby";
import { ProductCategoryPageContextType, ProductCategoryPageDataType } from "./types";
import SEO from "../../seo";
import Layout from "../../layout";
import ProductsRows from "../../products-rows";
import Pagination from "../../pagination";
import Modal from "../../modal";

export default function Category({ data, pageContext }: PageProps<ProductCategoryPageDataType, ProductCategoryPageContextType>) {
  const {
    category,
    buildMetadata: {
      buildTime
    },
    productsList: {
      edges: products
    }
  } = data;

  const {
    numOfPages,
    currentPageIndex
  } = pageContext;

  const productDates = {
    first_publication_date: buildTime,
    last_publication_date: buildTime,
  };

  return (
    <Layout>
      <SEO
        title={category.name}
        description={category.name}
        banner={""}
        pathname={`/category/${category.slug}/`}
        node={productDates}
      />

      <div className={"container"}>

        <Modal />

        <section className="py-5 bg-light">
          <div className="container">
            <div className="row px-4 px-lg-5 py-lg-4 align-items-center">
              <div className="col-lg-6">
                <h1 className="h2 text-uppercase mb-0">
                  {category.name}
                </h1>
              </div>
              <div className="col-lg-6 text-lg-end">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb justify-content-lg-end mb-0 px-0 bg-light">
                    <li className="breadcrumb-item">
                      <Link
                        to={"/"}
                        className="text-dark"
                      >
                        Accueil
                      </Link>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      <Link
                        to={"/shop/"}
                        className="text-dark"
                      >
                        Boutique
                      </Link>
                    </li>
                    <li
                      className="breadcrumb-item active"
                      aria-current="page"
                    >
                      {category.name}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>

        <section className="py-5">
          <ProductsRows
            products={products}
            columnClassName={"col-xl-3 col-lg-4 col-sm-6"}
          />
        </section>
        {/* PAGINATION */}
        <Pagination
          numOfPages={numOfPages}
          currentPageIndex={currentPageIndex || 0}
          mainPath={`/category/${category.slug}`}
        />

      </div>

    </Layout>
  )
}

export const pageQuery = graphql`
  query ($id: Int!, $limit: Int!, $skip: Int!) {
    buildMetadata: siteBuildMetadata {
      buildTime(formatString: "YYYY-MM-DD")
    }
    productsList: allWcProducts(
      filter: {
        category_ids: {
          eq: $id
        }
      }
      limit: $limit,
			skip: $skip
    ) {
      totalCount
      edges {
        node {
          wordpress_id
          slug
          name
          regular_price
          price
          description
          images {
            src
            width
            height
            is_resized
          }
        }
      }
    }
    category: wcProductsCategories (
      term_id: {
        eq: $id 
      }
    ) {
      id
      name
      slug
      taxonomy
      count
    }
  }
`;